// Bootstrap 4 用のコードだったものを transition をふつうに使う形に変更

function eventFired($element, event) {
    return new Promise(resolve => $element.one('transitionend', resolve));
}

async function enter($target, className) {
    $target.data('transitioning', true);
    $target.removeClass(`${className}-leave`);
    $target.addClass(`${className}`);

    await eventFired($target, 'transitionend');

    $target.data('transitioning', false);
}

async function leave($target, className) {
    $target.data('transitioning', true);
    $target.removeClass(`${className}`);
    $target.addClass(`${className}-leave`);

    await eventFired($target, 'transitionend');

    $target.data('transitioning', false);
}

$(document).on('click', '[data-transition]', function (event) {
    const $this = $(this);
    const $target = $($this.data('target'));
    const className = $this.data('transition');
    const only = $this.data('only');

    if ($target.data('transitioning')) {
        return;
    }

    if ($target.hasClass(className) && only !== 'enter') {
        leave($target, className);
    }
    if (!$target.hasClass(className) && only !== 'leave') {
        enter($target, className);
    }
});

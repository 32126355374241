<template>
  <div class="input-group vfc-input-group">
    <div class="vfc-left-date vfc-flex">
      <DatePicker :name="left.date.name" :placeholder="left.date.placeholder"
                  :initial-value="left.date.value" :input-id="left.date.id"
                  :invalid="left.date.invalid" :limits="left.date.limits"
                  @choseDay="handleLeftChoseDate"></DatePicker>
    </div>
    <div class="vfc-left-time vfc-flex" v-if="left.time">
      <TimePicker :name="left.time.name" :input-id="left.time.id" :invalid="left.time.invalid"
                  :placeholder="left.time.placeholder" :initial-value="left.time.value"
                  @choseTime="handleLeftChoseTime"/>
    </div>
    <div class="input-group-prepend input-group-text">
      <span>{{ termDashLabel }}</span>
    </div>
    <div class="vfc-right-date vfc-flex">
      <DatePicker :name="right.date.name" :placeholder="right.date.placeholder"
                  :initial-value="right.date.value" :input-id="right.date.id"
                  :invalid="right.date.invalid" :limits="right.date.limits"
                  @choseDay="handleRightChoseDate"></DatePicker>
    </div>
    <div class="vfc-right-time vfc-flex" v-if="right.time">
      <TimePicker :times="times59" :name="right.time.name" :input-id="right.time.id"
                  :invalid="right.time.invalid" :placeholder="right.time.placeholder"
                  :initial-value="right.time.value" @choseTime="handleRightChoseTime"/>
    </div>
  </div>
</template>

<script>
import DatePicker from '../components/date-picker';
import TimePicker from '../components/time-picker';
import {toCalendarDate, toRailsDate} from '../lib/helpers'
import VueTurboCache from '../lib/vue-turbo-cache';

export default {
  components: {
    DatePicker, TimePicker
  },
  data() {
    return {
      termDashLabel: '〜',
      left: {},
      right: {},
      times59: new Array(24).fill(0).map((e, i) => `${String(i).padStart(2, '0')}:59`)
    }
  },
  methods: {
    handleLeftChoseDate(date) {
      this.right.date.limits.min = toCalendarDate(date);
      this.updateCache('leftDatepicker', toRailsDate(date));
    },
    handleRightChoseDate(date) {
      this.left.date.limits.max = toCalendarDate(date);
      this.updateCache('rightDatepicker', toRailsDate(date));
    },
    handleLeftChoseTime(time) {
      this.updateCache('leftTimepicker', time);
    },
    handleRightChoseTime(time) {
      this.updateCache('rightTimepicker', time);
    },
    storeCache() {
      const fragment = VueTurboCache.fetch(this.$root);

      const [leftDatepicker, rightDatepicker] = fragment.querySelectorAll('.datepicker');
      const [leftTimepicker, rightTimepicker] = fragment.querySelectorAll('.timepicker');

      this.$turbolinksCache = {fragment, leftDatepicker, rightDatepicker, leftTimepicker, rightTimepicker};
    },
    updateCache(locator, value) {
      this.$turbolinksCache[locator].setAttribute('value', value);
      VueTurboCache.store(this.$root, this.$turbolinksCache.fragment);
    },
    deconstructElement(element) {
      return {
        name: element.getAttribute('name'),
        placeholder: element.getAttribute('placeholder'),
        value: element.value,
        id: element.getAttribute('id'),
        invalid: element.classList.contains('is-invalid')
      }
    }
  },
  beforeMount() {
    [this.left.date, this.right.date] = Array.from(this.$root.$el.querySelectorAll('.datepicker')).map(this.deconstructElement);
    this.left.date.limits = {min: '', max: toCalendarDate(this.right.date.value)};
    this.right.date.limits = {min: toCalendarDate(this.left.date.value), max: ''};

    [this.left.time, this.right.time] = Array.from(this.$root.$el.querySelectorAll('.timepicker')).map(this.deconstructElement);
    this.termDashLabel = this.$root.$el.querySelector('.input-group-text').textContent

    this.storeCache();
  }
}
</script>

<style>
.vfc-input-group > .vfc-flex {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.vfc-input-group > .vfc-flex .form-control {
  border-radius: 0;
}

.vfc-input-group > .vfc-flex .form-control.is-invalid {
  position: relative;
  z-index: 2;
}

.vfc-input-group > .vfc-flex .form-control:focus {
  position: relative;
  z-index: 3;
}

.vfc-input-group > .vfc-flex + .vfc-flex {
  margin-left: -1px;
}

.vfc-input-group > .vfc-flex:first-child .form-control {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.vfc-input-group > .vfc-flex:last-child .form-control {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
</style>

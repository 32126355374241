require("@rails/ujs").start();
require("turbolinks").start();
require("trix")

import './lib/trix-video-button';
import './lib/trix-center-button';
import './lib/trix-color-button';
import './lib/turbolinks-form';
import './lib/turbolinks-scroll';
import './lib/request-and-reload';
import './lib/training-secret';

import Vue from 'vue';
import TurbolinksAdapter from "vue-turbolinks";
import './lib/axios_config';
import PadZero from './lib/pad-zero';
import FixVideoAspect from './lib/fix-video-aspect';

import InputDateRange from './contents/input-date-range';
import CategoryPicker from './contents/category-picker';
import InputActiveStorage from './contents/input-active-storage';
import NextPage from './contents/next-page';
import ContentPreview from './contents/content-preview';
import TagBuilder from './contents/tag-builder';
import TrainingCategoryBuilder from './training/training-category-builder';
import TrainingCategoryPicker from './training/training-category-picker';
import TrainingHeadlineDate from './training/training-headline-date';
import Content from './lib/content';

window.$ = window.jQuery = require('jquery')
// トップレベルで $ を使っているもの
require('./lib/jquery-transition');
require('./lib/jquery-back-button');
require('./lib/action-text');

window.bootstrap = require("bootstrap")

// ブラウザーバックでVueが壊れるのを防ぐ
Vue.use(TurbolinksAdapter);

document.addEventListener("turbolinks:load", () => {
    const apps = [
        {el: '#input-date-range', app: InputDateRange},
        {el: '#input-date-range-modal', app: InputDateRange},
        {el: '#category-picker', app: CategoryPicker},
        {el: '#input-active-storage', app: InputActiveStorage},
        {el: '#next-page', app: NextPage},
        {el: '#content-preview', app: ContentPreview},
        {el: '#tag-builder', app: TagBuilder},
        {el: '#training-category-builder', app: TrainingCategoryBuilder},
        {el: '#training-category-picker', app: TrainingCategoryPicker},
        {el: '#training-headline-date', app: TrainingHeadlineDate},
    ];
    apps.forEach(({el, app}) => {
        if (!document.querySelector(el)) {
            return;
        }
        if (app.render) {
            new Vue({el: el, render: h => h(app)});
        } else {
            new Vue(app).$mount(el);
        }
    });

    PadZero.start();
    FixVideoAspect.fix();
    Content();
});
